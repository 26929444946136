.content-container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}

.games-list {
  padding: 2rem;
  margin: 0;
  background: rgba(240,240,240,.5);

  .game {
    box-shadow: 3px 3px 3px rgba(69,69,69, .5);
    margin: .5rem 0;
    list-style: none;
    background-color: white;
    transition: background-color .5s;

    &:hover, &:focus {
      background-color: rgb(240,240,240);
    }

    &__link-title {
      font-size: 2rem;
      font-weight: bold;
      display: block;
      padding: 1rem;
    }

    &__description {
      font-style: italic;
    }
  }
}
